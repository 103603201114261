export const parseResult = (result) => {
  if (typeof result === "string") {
    try {
      return JSON.parse(result.replace(/'/g, '"'));
    } catch (error) {
      console.error("Failed to parse result:", error);
      return [];
    }
  }
  //   return Array.isArray(result) ? result : [];
  return result || [];
};
